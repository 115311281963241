@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@400;500;700;800;900&display=swap');


p {
  margin: 0;
  font-size: 20px;
}

input {    
  width: 49vw;
  max-width: 300px;
  min-width: 220px;
  margin-top: 3rem;
  padding: 6px 16px 11px 16px;
  background: rgb(249 218 254);
  border: 2px solid var(--Strokes-Focus, rgba(230, 144, 245, 1));
  color: rgba(108, 23, 123, 1);
  border-radius: 24px;
  font-size: 20px;
  font-weight: bold;
}

input:focus {
  outline: none !important;
}

input::placeholder {
  color: rgba(108, 23, 123, 1);
}

button {    
  width: 55vw;
  height: 48px;
  max-width: 336px;
  min-width: 250px;
  margin-top: 1rem;
  margin-bottom: 4rem;
  padding: 0px 16px;
  background: rgba(35, 0, 41, 1);
  border-color: transparent;
  color:rgba(249, 218, 254, 1);
  border-radius: 24px;
  font-size: 20px;
  font-weight: 400;
}

button:disabled {
  background: rgb(249 218 254);
  color: rgba(230, 144, 245, 1);
}

button:hover {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-medium {
  font-size: 30px;
  font-weight: 700;
}
.mt-10 {
  margin-top: 10px;
}

.mt-40 {
  margin-top: 40px;
}

.bg-purple {
  background-color: rgba(249, 218, 254, 1);
}

.bg-black-purple {
  background-color: rgba(35, 0, 41, 1);
}

.purple {
  color: rgba(249, 218, 254, 1);
}

.pink {
  color: rgba(230, 144, 245, 1);
}

.black-purple {
  color: rgba(35, 0, 41, 1)
}


@media only screen and (max-width: 810px) {

  button {
    font-size: 16px;
  }

  p {
    margin: 0;
    font-size: 16px;
  }

  .text-medium {
    font-size: 26px;
    font-weight: 700;
  }

  .text-center-mobile {
    text-align: center;
  }

}
