@font-face {
  font-family: 'Nudica';
  src: url('../src/resources/assets/fonts/Nudica-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Nudica';
  src: url('../src/resources/assets/fonts/Nudica-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Nudica';
  src: url('../src/resources/assets/fonts/Nudica-UltraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Nudica';
  src: url('../src/resources/assets/fonts/Nudica-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nudica';
  src: url('../src/resources/assets/fonts/Nudica-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Nudica';
  src: url('../src/resources/assets/fonts/Nudica-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nudica';
  src: url('../src/resources/assets/fonts/Nudica-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Nudica';
  src: url('../src/resources/assets/fonts/Nudica-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Nudica';
  src: url('../src/resources/assets/fonts/Nudica-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Nudica';
  src: url('../src/resources/assets/fonts/Nudica-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nudica';
  src: url('../src/resources/assets/fonts/Nudica-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}


body {
  overflow-x: hidden;
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-family: 'Nudica', sans-serif;
  font-weight: 400;
}

input, button {
  font-family: 'Nudica', sans-serif;
  font-weight: 400; 
}
