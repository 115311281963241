.slide {
  position: relative;
  width: 22vw;
  height: 22vw;
  padding: 30px;
  text-align: center;
  margin: auto;
}

.slide p {
  font-weight: 700;
  font-size: 25px;
}

.step {
  background-size: contain;
  background-position: center;
  width: 18vw;
  height: 18vw;
  margin: auto;
}

.step_1_spoter {
  background-image: url('../../resources/img/step_1_spoter.png');
}

.step_2_spoter {
  background-image: url('../../resources/img/step_2.png');
}

.step_3_spoter {
  background-image: url('../../resources/img/step_3_spoter.png');
}

.step_1_spectator {
  background-image: url('../../resources/img/step_1_spectator.png');
}

.step_2_spectator {
  background-image: url('../../resources/img/step_2.png');
}

.step_3_spectator {
  background-image: url('../../resources/img/step_3_spectator.png');
}

@media only screen and (max-width: 810px) {
  .slide {
    width: 75vw;
    height: 50vw;
    padding: 10px;
  }

  .step {
    width: 45vw;
    height: 45vw;
  }

  .slide p {
    font-size: 5vw;
  }

}