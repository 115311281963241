.cgu-container {
  background-image: url('../../resources/img/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 100px;
  padding-bottom: 100px;
}


@media only screen and (max-width: 810px) {
  .cgu-container {
    padding: 0 25px;
    padding-bottom: 50px;
  }
}