.sticky-footer {
  background-image: url('./../../resources/img/footer.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  padding-top: 30px;
  text-align: center;
}

.sticky-footer p {
  margin-top: 25px;
}

.sticky-footer a {
  cursor: pointer;
}


@media only screen and (max-width: 810px) {

  .sticky-footer p {
    font-size: 16px;
  }


  .sticky-footer a {
    font-size: 14px;
  }

}

