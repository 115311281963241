.slider-container {
  position: relative;
  margin-top: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slides-wrapper {
  width: 100%;
  overflow: hidden;
}

.slides {
  display: flex;
  width: 200%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.show-first {
  transform: translateX(0);
}

.show-second {
  transform: translateX(-50%);
}

.slide-set {
  display: flex;
  width: 50%;
  justify-content: space-around;
}

@media only screen and (max-width: 810px) {
  .slider-container {
    margin-top: 1vh;
  }

  .slide-set {
    flex-direction: column;
  }
}