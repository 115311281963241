.sticky-header {
  height: 72px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #f3e1ff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.logo {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 20px;
}

.logo-icon {
  background-image: url('./../../resources/img/favicon.svg');
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
}

.logo-text {
  color: rgba(35, 0, 41, 1);
  font-size: 30px;
  font-weight: bold;
  padding-left: 5px;
  line-height: 33px;
  padding-top: 3px;
  align-self: flex-start;
}

.nav-pills {
  width: fit-content;
  margin: auto;
  background: var(--Surfaces-Surface, rgba(249, 218, 254, 1));
  box-shadow: 0px 0px 40px 0px rgba(108, 23, 123, 0.3);
  padding: 3px;
  border-radius: 40px;
  display: flex;
  gap: 12px;
}

.pill {
  height: 40px;
  width: auto;
  min-width: auto;
  margin: 0;
  padding-bottom: 4px;
  background: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-top: 3px;
  color: rgba(230, 144, 245, 1);
  transition: background 0.3s, color 0.3s;
}

.pill.active {
  background: var(--Surfaces-Brand-purple, rgba(230, 144, 245, 1));
  color: rgba(35, 0, 41, 1);
}

.store-icons {
  display: flex;
  gap: 10px;
  padding-right: 20px;
}

.icon-container {
  cursor: pointer;
  height: 60px;
  width: 90px;
  border-radius: 35px
}

.icon {
  margin: auto;
  background: no-repeat;
  background-size: cover;
}

.appstore {
  background-image: url('../../resources/img/apple_logo.png');
  height: 35px;
  width: 28px;
  margin-top: 10px;
}
.playstore {
  background-image: url('../../resources/img/playstore.svg');
  height: 50px;
  scale: 0.9;
}

@media only screen and (max-width: 810px) {
  .nav-pills-header {
    display: none;
  }

  .nav-pills {
    width: 80%;
  }

  .pill {
    font-size: 3.5vw;
    width: 55vw;
  }

  .store-icons {
    gap: 10px;
    padding-right: 14px;
  }

  .icon-container {
    height: 45px;
    width: 65px;
    border-radius: 35px
  }

  .playstore {
    scale: 0.8;
    height: 40px;
  }

  .appstore {
    height: 25px;
    width: 20px;
    margin-top: 8px;
  }
}
