.header {
  padding-top: 5rem;
}

.title {
  font-size: 40px;
  margin: 0;
  font-weight: 700;
}

.spoteet-icon {
  width: 100px;
  height: 100px;
  background-image: url('./../../resources/img/favicon.svg');
  background-size: cover;
  background-position: center;
  margin: auto;
}